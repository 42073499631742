import React, { ReactNode } from 'react';
import { Alert } from '@ver-uds/uswds-react';
import AlertForm from '../components/AlertForm/AlertForm';

export interface AlertPageProps {
  title?: string;
  alertHeading?: string;
  alertText?: ReactNode;
  continueBtnText?: string;
  continueBtnUrl?: string;
}

const AlertPage = ({
  title = 'Alert',
  alertHeading = 'Password Recovery',
  alertText = 'For a password reset or other assistance, contact your Administrator.',
  continueBtnText = 'Back to Sign In',
  continueBtnUrl = '/',
}: AlertPageProps): JSX.Element => {
  const alert = (
    <Alert status="warning">
      <Alert.Heading>{alertHeading}</Alert.Heading>
      <Alert.Text>{alertText}</Alert.Text>
    </Alert>
  );

  return <AlertForm title={title} alert={alert} continueBtnText={continueBtnText} continueBtnUrl={continueBtnUrl} />;
};

export default AlertPage;
