import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Fieldset, Legend } from '@ver-uds/uswds-react';
import DHSFormWrapper from '../DHSFormWrapper/DHSFormWrapper';

export interface AlertFormProps {
  helmet?: string;
  title: string;
  alert: ReactNode;
  text?: ReactNode | undefined;
  continueBtnUrl?: string | undefined;
  continueBtnText?: string | undefined;
  cancelBtnText?: string | undefined;
  cancelBtnUrl?: string | undefined;
}

const ContinueButton = ({ url, text }: { url: string; text: string }): JSX.Element => {
  if (url.startsWith('http')) {
    return (
      <a href={url} className="usa-button margin-top-3">
        {text}
      </a>
    );
  }

  return (
    <Link to={url} className="usa-button margin-top-3">
      {text}
    </Link>
  );
};

const AlertForm = ({
  helmet,
  title,
  alert,
  text,
  continueBtnText = 'Continue',
  continueBtnUrl,
  cancelBtnText,
  cancelBtnUrl = '/',
}: AlertFormProps): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>{helmet || title}</title>
      </Helmet>
      <DHSFormWrapper>
        <Fieldset>
          <Legend>
            <h2>{title}</h2>
          </Legend>
          {alert}
          {text && text}
          {cancelBtnText && (
            <a href={cancelBtnUrl} className="usa-button usa-button--outline margin-top-3">
              {cancelBtnText}
            </a>
          )}
          {continueBtnUrl && <ContinueButton url={continueBtnUrl} text={continueBtnText} />}
        </Fieldset>
      </DHSFormWrapper>
    </>
  );
};

export default AlertForm;
