import {
  RegistrationRequestApplicationEnum,
  RenewalRequestApplicationEnum,
  ServerError,
  ValidationError,
  ValidationErrorCode,
} from '@vis-auth/vis-registration-client';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Application } from '@vis-auth/vis-user-client';
import { EnrollmentState, SaveUserAccountForm, UserAccountForm } from '../../components/Enrollment/constants';
import { NewUserAccountSave } from '../../components/Enrollment/NewUserAccountSave';
import { TermsOfUse } from '../../components/Enrollment/TermsOfUse';
import { mapError, useRegisterUser, useRenewRegistration } from '../../registrationService';
import EmailSentSave from '../../components/Enrollment/EmailSentSave';
import EnrollmentInProccessModal from '../../components/Enrollment/EnrollmentInProccessModal';

const SaveEnroll = (): JSX.Element => {
  const [enrollmentStatus, setEnrollmentStatus] = useState<EnrollmentState | undefined>(undefined);
  const formMethods = useForm<SaveUserAccountForm>({});
  const { watch } = formMethods;
  const email = watch('emailAddress');
  const [enrollError, setEnrollError] = useState<ServerError | ValidationError | null>(null);
  const [renewError, setRenewError] = useState<ServerError | ValidationError | null>(null);

  const [mutate, { isLoading: isLoadingRegister }] = useRegisterUser({
    onSuccess: () => {
      setEnrollmentStatus(EnrollmentState.ENROLLMENT_ACCEPTED);
    },
    onError(error) {
      setEnrollError(error);
    },
  });

  const [renewRegistration, { isLoading: isLoadingRenew }] = useRenewRegistration({
    onSuccess: () => {
      setEnrollError(null);
      setEnrollmentStatus(EnrollmentState.ENROLLMENT_ACCEPTED);
    },
    onError(error) {
      setRenewError(error);
    },
  });

  const isLoading = isLoadingRegister || isLoadingRenew;

  const onSubmit: SubmitHandler<UserAccountForm> = async (data: UserAccountForm): Promise<void> => {
    setEnrollError(null);
    await mutate({
      application: RegistrationRequestApplicationEnum.SAVE,
      email: data.emailAddress,
      firstName: data?.firstName,
      lastName: data?.lastName,
      middleInitial: data?.middleInitial ? data.middleInitial : undefined,
      phoneNumber: {
        number: data?.phoneNumber,
        extension: undefined,
      },
    });
  };

  const showInProgressModal = enrollError?.code === ValidationErrorCode.OTCPENDING;

  if (!enrollmentStatus) {
    return (
      <>
        <Helmet>
          <title>Use Agreement</title>
        </Helmet>
        <TermsOfUse
          app={Application.SAVE}
          setTermsAccepted={() => setEnrollmentStatus(EnrollmentState.TERMS_ACCEPTED)}
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Create New User Account</title>
      </Helmet>
      <NewUserAccountSave
        formMethods={formMethods}
        onSubmit={onSubmit}
        submissionError={!showInProgressModal && enrollError !== null}
      />
      <EmailSentSave open={enrollmentStatus === EnrollmentState.ENROLLMENT_ACCEPTED} email={email} />
      <EnrollmentInProccessModal
        open={showInProgressModal}
        close={() => {
          setRenewError(null);
          setEnrollError(null);
        }}
        error={renewError ? mapError(renewError) : ''}
        isLoading={isLoading}
        sendConfirmation={() => {
          setRenewError(null);
          // eslint-disable-next-line no-void
          void renewRegistration({
            renewalRequest: {
              application: RenewalRequestApplicationEnum.SAVE,
              email,
            },
          });
        }}
      />
    </>
  );
};

export default SaveEnroll;
