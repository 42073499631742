import { Modal } from '@ver-uds/react';
import React from 'react';
import { useEnvironment } from '../Environment/EnvironmentContext';

export default function EmailSentSave({ open, email }: { open: boolean; email?: string }) {
  const env = useEnvironment();
  return (
    <Modal
      id="enrollment-confirm-modal"
      open={open}
      header="Confirmation Required"
      hasDismissButton={false}
      close={() => {}}
    >
      <Modal.Content>
        <div className="padding-bottom-4">
          <p>
            A message has been sent to {email ? <b>{email}</b> : 'your email'} containing a link to confirm your
            identity and complete the registration process.
          </p>
          <p>
            If you do not receive an email, please contact SAVE support at{' '}
            <a href="mailto:SAVE.help@uscis.dhs.gov">SAVE.help@uscis.dhs.gov</a>
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <a href={env.SAVE_ENROLLMENT_REDIRECT} className="usa-button" rel="noopener noreferrer">
          Exit
        </a>
      </Modal.Footer>
    </Modal>
  );
}
