import React from 'react';
import { Alert } from '@ver-uds/uswds-react';

export interface IEDeprecationAlertProps {
  showAlert?: boolean;
}

const IEDeprecationAlert = ({ showAlert }: IEDeprecationAlertProps): JSX.Element | null =>
  showAlert ? (
    <Alert status="error" className="margin-bottom-5">
      <Alert.Text>
        Your browser is not supported by this application. Please click any of the links below to download the latest
        versions of these supported browsers:
      </Alert.Text>
      <ul>
        <li>
          <a
            href="https://www.microsoft.com/en-us/edge"
            aria-label="Download Microsoft Edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            href="https://www.google.com/chrome/downloads/"
            aria-label="Download Google Chrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            aria-label="Download Mozilla Firefox"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            href="https://support.apple.com/downloads/safari"
            aria-label="Download Safari"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari
          </a>
        </li>
      </ul>
    </Alert>
  ) : null;

export default IEDeprecationAlert;
