import React from 'react';
import { Alert } from '@ver-uds/uswds-react';
import AlertForm from '../components/AlertForm/AlertForm';
import { useUserData } from '../components/Providers/UserDataProvider';

const PasswordExpireWarning = (): JSX.Element => {
  // eslint-disable-next-line camelcase
  const { authorizationCode, daysToPasswordExpire, redirect_uri, state } = useUserData();
  const alert = (
    <Alert status="warning">
      <Alert.Heading>Your password will expire in {daysToPasswordExpire} day(s).</Alert.Heading>
      <Alert.Text>Would you like to update your password now?</Alert.Text>
    </Alert>
  );

  return (
    <AlertForm
      title="Expiring Password"
      alert={alert}
      continueBtnText="Yes"
      continueBtnUrl="/password-expiring/reset-password"
      cancelBtnText="No"
      // eslint-disable-next-line camelcase
      cancelBtnUrl={`${redirect_uri}?code=${authorizationCode || ''}&state=${state}`}
    />
  );
};

export default PasswordExpireWarning;
