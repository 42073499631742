import React, { useContext, FunctionComponent, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UserStatus } from '@vis-auth/vis-user-client';
import { useEnvironment } from '../Environment/EnvironmentContext';
import { parseSearchParams, ParsedSearchParamsResult, ParsedSearchParams } from './OAuthParamsParser';

type UserDataContextVariables = ParsedSearchParamsResult & {
  logonId?: string;
  userId?: string;
  userStatus?: UserStatus;
  authorizationCode?: string;
  daysToPasswordExpire?: number;
  setLogonId: (logonId: string) => void;
  setUserId: (userId: string) => void;
  setUserStatus: (UserStatus: UserStatus) => void;
  setAuthorizationCode: (authCode: string) => void;
  calcDaysToPasswordExpire: (expirationDate: Date) => number;
};

type UserDataContextValue = (ParsedSearchParamsResult & UserDataContextVariables) | null;

export type UserData = ParsedSearchParams & UserDataContextVariables;

const UserDataContext = React.createContext<UserDataContextValue>(null);
export const useUserData = (): UserData => {
  const userData = useContext(UserDataContext);
  if (userData === null || 'error' in userData) {
    throw new Error('Required user data or OAuth params are missing.');
  }
  return userData;
};

export const UserDataProvider: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const env = useEnvironment();

  const [parsedSearchParams] = useState<ParsedSearchParamsResult>(() => parseSearchParams(location.search, env));

  const [logonId, setLogonId] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [userStatus, setUserStatus] = useState<UserStatus>();
  const [authorizationCode, setAuthorizationCode] = useState<string>();
  const [daysToPasswordExpire, setDaysToPasswordExpire] = useState<number>();

  function calcDaysToPasswordExpire(expirationDate: Date): number {
    const today = new Date();
    const daysToExpire = Math.ceil((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    setDaysToPasswordExpire(daysToExpire);
    return daysToExpire;
  }

  const userDataValue = useMemo(
    () => ({
      ...parsedSearchParams,
      logonId,
      userId,
      userStatus,
      authorizationCode,
      daysToPasswordExpire,
      setLogonId,
      setUserId,
      setUserStatus,
      setAuthorizationCode,
      calcDaysToPasswordExpire,
    }),
    [authorizationCode, daysToPasswordExpire, logonId, parsedSearchParams, userId, userStatus],
  );
  return <UserDataContext.Provider value={userDataValue}>{children}</UserDataContext.Provider>;
};
