/* eslint-disable react/require-default-props */
import React, { ReactElement, useReducer, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { ButtonGroup, Fieldset, Legend, Submit, TextInputControl, Alert, Button } from '@ver-uds/uswds-react';
import { Button as ShowButton } from '@ver-uds/react';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';
import DHSFormWrapper from '../DHSFormWrapper/DHSFormWrapper';

interface ChangePasswordFormProps {
  onSubmit: ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }) => Promise<boolean | void>;
  onCancel?: () => void;
  helmet: string;
  title: string;
  subtitle?: string;
  instructions?: string;
  submitBtnText?: string;
  cancelBtnText?: string;
  customCancelBtn?: React.ReactElement;
  currentPassRequired?: boolean;
  currentPassLabel?: string;
  currentPassLabelHint?: string;
  newPassLabel?: string;
  newPassLabelHint?: string;
  confirmPassLabel?: string;
  confirmPassLabelHint?: string;
  ariaLabel?: string;
  logonId?: string;
  status?: string;
  errorMessage?: string;
}

const ChangePasswordForm = ({
  onSubmit,
  onCancel,
  helmet,
  title,
  subtitle,
  instructions,
  submitBtnText = 'Continue',
  cancelBtnText = 'Cancel',
  customCancelBtn,
  currentPassRequired,
  currentPassLabel = 'Current Password',
  currentPassLabelHint,
  newPassLabel = 'New Password',
  newPassLabelHint,
  confirmPassLabel = 'Confirm New Password',
  confirmPassLabelHint,
  ariaLabel = 'Change password',
  logonId = '',
  status,
  errorMessage,
}: ChangePasswordFormProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<{
    currentPassword: string;
    newPassword: string;
    confirmedPassword: string;
  }>({
    reValidateMode: 'onSubmit',
  });

  const [currentPassword, newPassword] = watch(['currentPassword', 'newPassword']);
  const [showCurrentPassword, toggleShowCurrentPassword] = useReducer((show) => !show, false);
  const [showPassword, toggleShowPassword] = useReducer((show) => !show, false);

  const alertElement = useRef<HTMLDivElement>(null);

  // Using a hook here leads to the following warning that has `useForm` in its stacktrace:
  // Cannot update a component (`ChangePasswordForm`) while rendering a different component (`ValidationList`).
  // Passing in a callback manually resolves this.
  const newPasswordValidRef = useRef(false);

  const setIsNewPasswordValid = (valid: boolean): void => {
    newPasswordValidRef.current = valid;
  };

  let cancelButton;
  if (onCancel) {
    cancelButton = customCancelBtn || (
      <Button type="button" variant="outline" onClick={onCancel}>
        {cancelBtnText}
      </Button>
    );
  }

  return (
    <>
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      <DHSFormWrapper>
        <form
          aria-label={ariaLabel}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(async (): Promise<void> => {
            await onSubmit({ currentPassword, newPassword });
            if (alertElement.current) {
              alertElement.current.focus();
            }
          })}
        >
          <Fieldset>
            <Legend>
              <h2>{title}</h2>
            </Legend>
            {subtitle && <h3>{subtitle}</h3>}

            {instructions && <p className="margin-top-3 margin-bottom-4">{instructions}</p>}

            {errorMessage ? (
              <div id="change-password-error" className="margin-y-3" tabIndex={-1} ref={alertElement}>
                <Alert status="error">
                  <Alert.Heading>Password Change failed</Alert.Heading>
                  {errorMessage.split('\r\n').map((msg) => {
                    return <Alert.Text key={msg}>{msg}</Alert.Text>;
                  })}
                </Alert>
              </div>
            ) : null}
            <div className="margin-bottom-5">
              {currentPassRequired ? (
                <TextInputControl
                  id="currentPassword"
                  label={currentPassLabel}
                  labelHint={currentPassLabelHint}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('currentPassword', { required: 'Current password is required' })}
                  errorMessage={errors.currentPassword && errors.currentPassword.message}
                  errorId="current-password-error"
                  type={showCurrentPassword ? 'text' : 'password'}
                  note={
                    <ShowButton
                      // className="margin-bottom-5"
                      variant="tertiary"
                      type="button"
                      aria-controls="currentPassword"
                      onClick={toggleShowCurrentPassword}
                    >
                      {showCurrentPassword ? 'Hide Password' : 'Show Password'}
                    </ShowButton>
                  }
                />
              ) : null}
            </div>
            <PasswordRequirements
              password={newPassword}
              logonId={logonId}
              id="password-requirements"
              oldPassword={currentPassword}
              checkConsecutiveChars={currentPassRequired}
              setIsValid={setIsNewPasswordValid}
            />
            <div className="margin-bottom-5">
              <TextInputControl
                id="newPassword"
                label={newPassLabel}
                labelHint={newPassLabelHint}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('newPassword', {
                  required: 'New password is required',
                  validate: () => newPasswordValidRef.current || 'New password does not meet requirements.',
                })}
                aria-describedby="password-requirements"
                errorMessage={errors.newPassword && errors.newPassword.message}
                errorId="new-password-error"
                type={showPassword ? 'text' : 'password'}
                note={
                  <ShowButton
                    variant="tertiary"
                    type="button"
                    aria-controls="newPassword confirmPassword"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? 'Hide Password' : 'Show Password'}
                  </ShowButton>
                }
              />
            </div>
            <div className="margin-bottom-5">
              <TextInputControl
                id="confirmedPassword"
                label={confirmPassLabel}
                labelHint={confirmPassLabelHint}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('confirmedPassword', {
                  required: 'Confirm new password is required',
                  validate: (value) => value === newPassword || 'New passwords must match',
                })}
                errorMessage={errors.confirmedPassword && errors.confirmedPassword.message}
                errorId="confirm-new-password-error"
                type={showPassword ? 'text' : 'password'}
                note={
                  <ShowButton
                    variant="tertiary"
                    type="button"
                    aria-controls="newPassword confirmPassword"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? 'Hide Password' : 'Show Password'}
                  </ShowButton>
                }
              />
            </div>
            <ButtonGroup>
              {cancelButton as ReactElement}
              <Submit value={submitBtnText} disabled={status === 'loading'} />
            </ButtonGroup>
          </Fieldset>
        </form>
      </DHSFormWrapper>
    </>
  );
};

export default ChangePasswordForm;
