import React from 'react';
import { Button, Modal } from '@ver-uds/react';
import { Alert } from '@ver-uds/uswds-react';

interface AlertModalProps {
  alertStatus: 'success' | 'warning' | 'error' | 'info';
  headerText: string;
  contentHeading: string;
  contentText: string;
  buttonText: string;
  isOpen: boolean;
  onClose: () => void;
}

const AlertModal = ({
  alertStatus,
  contentHeading,
  contentText,
  headerText,
  buttonText,
  isOpen,
  onClose,
}: AlertModalProps): JSX.Element => {
  const handleCloseModal = (): void => {
    onClose();
  };

  Modal.setAppElement('#root');

  return (
    <Modal close={handleCloseModal} header={headerText} id="centered-modal" open={isOpen}>
      <Modal.Content>
        <Alert status={alertStatus}>
          <Alert.Heading>{contentHeading}</Alert.Heading>
          <Alert.Text>{contentText}</Alert.Text>
        </Alert>
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={handleCloseModal} type="button" variant="primary">
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
