import { Button, Modal } from '@ver-uds/react';
import { Application } from '@vis-auth/vis-user-client';
import React, { useState } from 'react';
import UnstyledButton from '../UnstyledButton/UnstyledButton';

const PaperworkReductionActModal = ({
  app,
  isStyled = false,
  className,
}: {
  app: Application;
  isStyled?: boolean;
  className?: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isStyled ? (
        <Button variant="tertiary" className={className} onClick={() => setIsOpen(true)}>
          Paperwork Reduction Act
        </Button>
      ) : (
        <UnstyledButton type="button" variant="tertiary" onClick={(): void => setIsOpen(true)}>
          Paperwork Reduction Act
        </UnstyledButton>
      )}
      <Modal
        id="paper-reduction-act-modal"
        header="Paperwork Reduction Act"
        open={isOpen}
        close={(): void => setIsOpen(false)}
      >
        <Modal.Content>
          {app === Application.EVERIFY ? (
            <p data-testid="EVE-paperwork-text">
              An agency may not conduct or sponsor information collection, and a person is not required to respond to a
              collection of information, unless it displays a valid Office of Management and Budget (OMB) control
              number. The public reporting burden for this information collection is estimated as follows: New
              Enrollment Activities at 136 minutes per response; Yearly Training to learn of changes to the process at
              60 minutes per response; Queries into the system at 8 minutes per response. Send comments regarding this
              burden estimate or any other aspect of this information collection, including suggestions for reducing
              this burden, to U.S. Citizenship and Immigration Services, Regulatory Coordination Division, Office of
              Policy and Strategy, 20 Massachusetts Avenue NW, Washington, DC 20529-2020.{' '}
              <strong>Do not mail your application to this address.</strong>
            </p>
          ) : (
            <p data-testid="SAVE-paperwork-text">
              USCIS may not conduct or sponsor an information collection, and you are not required to respond to a
              collection of information, unless it displays a currently valid Office of Management and Budget (OMB)
              control number. The public reporting burden for this collection of information is estimated at 5 minutes
              per response, including the time for reviewing instructions, gathering the required documentation and
              information, completing the request, preparing statements, attaching necessary documentation, and
              submitting the request. Send comments regarding this burden estimate or any other aspect of this
              collection of information, including suggestions for reducing this burden, to: U.S. Citizenship and
              Immigration Services, Office of Policy and Strategy, Regulatory Coordination Division, 5900 Capital
              Gateway Drive, Mail Stop #2140, Camp Springs, MD 20588-0009; OMB No. 1615-0101.{' '}
              <strong>Do not mail your completed verification requests to this address.</strong>
            </p>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button type="button" variant="primary" onClick={(): void => setIsOpen(false)}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaperworkReductionActModal;
