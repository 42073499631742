import React, { useState } from 'react';
import { Modal, Button } from '@ver-uds/react';

const UseAgreementModal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState((): boolean => {
    return !sessionStorage.getItem('consented');
  });

  const setConsent = (): void => {
    sessionStorage.setItem('consented', JSON.stringify(true));

    setIsOpen(false);
  };

  return (
    <Modal id="use-agreement-modal" header="Use Agreement" open={isOpen} close={setConsent} hasDismissButton={false}>
      <Modal.Content>
        <p>
          You are about to access a Department of Homeland Security computer system. This computer system and data
          therein are property of the U.S. Government and provided for official U.S. Government information and use.
          There is no expectation of privacy when you use this computer system. The use of a password or any other
          security measure does not establish an expectation of privacy. By using this system, you consent to the terms
          set forth in this notice. You may not process classified national security information on this computer
          system. Access to this system is restricted to authorized users only. Unauthorized access, use, or
          modification of this system or of data contained herein, or in transit to/from this system, may constitute a
          violation of section 1030 of title 18 of the U.S. Code and other criminal laws. Anyone who accesses a Federal
          computer system without authorization or exceeds access authority, or obtains, alters, damages, destroys, or
          discloses information, or prevents authorized use of information on the computer system, may be subject to
          penalties, fines or imprisonment. This computer system and any related equipment is subject to monitoring for
          administrative oversight, law enforcement, criminal investigative purposes, inquiries into alleged wrongdoing
          or misuse, and to ensure proper performance of applicable security features and procedures. DHS may conduct
          monitoring activities without further notice.
        </p>

        <p>
          By clicking &ldquo;I agree&rdquo; below or by using this system, you consent to the terms set forth in this
          notice.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button type="button" variant="primary" onClick={setConsent}>
          I Agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UseAgreementModal;
