import React from 'react';
import { Alert } from '@ver-uds/uswds-react';
import { useLocation } from 'react-router-dom';
import { Application, UserStatus } from '@vis-auth/vis-user-client';
import AlertForm from '../../components/AlertForm/AlertForm';
import AppShell from '../../components/Layouts/AppShell';

const OtcMessageSentPage = (): JSX.Element => {
  const searchParams = new URLSearchParams(useLocation().search);
  const userStatus = searchParams.get('userStatus') ?? '';
  const appParam = searchParams.get('app') ?? '';

  let app = '';
  let helpEmail;
  if (appParam.toLocaleLowerCase() === Application.SAVE.toLocaleLowerCase()) {
    app = Application.SAVE;
    helpEmail = 'SAVE.Help@uscis.dhs.gov';
  } else if (appParam.toLocaleLowerCase() === Application.EVERIFY.toLocaleLowerCase()) {
    app = Application.EVERIFY;
    helpEmail = 'E-Verify@uscis.dhs.gov';
  }

  if (!app || app === '') {
    throw new Error('Application context missing.');
  }

  let title = 'Reset Password';
  let alertText =
    'If an account exists for this User ID, instructions to reset your password have been sent to the email address associated with this account.';
  if (
    userStatus.toLocaleLowerCase() === UserStatus.LOCKED.toLocaleLowerCase() ||
    userStatus.toLocaleLowerCase() === UserStatus.DEACTIVATED.toLocaleLowerCase()
  ) {
    title = 'Account Locked';
    alertText = 'A password reset message has been sent to the email address associated with this User ID.';
  }

  const alert = (
    <Alert status="info">
      <Alert.Text>{alertText}</Alert.Text>
    </Alert>
  );

  const text = (
    <p>
      Please check email inboxes as well as spam or junk mail folders for emails sent from no-reply@uscis.dhs.gov, as
      sometimes the emails are mistakenly marked as spam. If you do not receive a password reset email, contact{' '}
      {helpEmail ? <a href={`mailto:${helpEmail}`}>{helpEmail}</a> : 'your administrator'} for assistance.
    </p>
  );

  return (
    <AppShell app={app}>
      <AlertForm title={title} alert={alert} text={text} />;
    </AppShell>
  );
};

export default OtcMessageSentPage;
