import {
  ServerError,
  BadRequestErrors,
  BadRequestError,
  NotFoundError,
  ValidationErrorCode,
  SystemErrorCode,
} from './userService';

export enum ServerErrorMessages {
  CODE_CHALLENGE = `Unable to log in.\r\n`,
  NOT_FOUND = `The service was not found. Wait a moment and try again.\r\n`,
  SERVER = `The service experienced a problem. Wait a moment and try again.\r\n`,
  GENERIC = `Something went wrong. Please wait a few minutes and try again. If the problem continues, contact your Administrator.\r\n`,
  INVALID_APP = `You do not have access to {{app}}. Check to ensure you are accessing the correct program and try logging in again.\r\n`,
}

enum ChangePasswordErrorMessages {
  CURRENT_PASSWORD = `Current Password is incorrect.\r\n`,
  NEW_PASSWORD = `New Password does not meet the requirements.\r\n`,
  DUPLICATE_PASSWORD = `New Password cannot be the same as Current Password.\r\n`,
  PASSWORD_LIFESPAN = `Password cannot be reset within 5 days of previously setting it. Please contact your Administrator.\r\n`,
  INTERNAL_ERROR = `An internal service error occurred while changing your password. Wait a moment and try again. If the problem continues, please contact your Administrator.\r\n`,
  GENERIC = `We encountered an error setting your new password. Please wait a few minutes and try again.\r\n`,
  PASSWORD_LENGTH = `New Password does not meet the length requirements.\r\n`,
}

enum LoginErrorMessages {
  FAILED = `User ID and/or password is incorrect.\r\n`,
}

export enum SendOTCErrorMessages {
  WEB_SERVICE_USER = `You have entered a Web Services Access ID. To reset the password, contact your Program Administrator or reset it using the {{app}} Web Services API.\r\n`,
}

interface ErrorMapping {
  regex: RegExp;
  message: string;
}

const ErrorMappings400: ErrorMapping[] = [
  { regex: /code challenge is invalid/i, message: ServerErrorMessages.CODE_CHALLENGE },
  { regex: /user's old password is invalid/i, message: ChangePasswordErrorMessages.CURRENT_PASSWORD },
  { regex: /^password is invalid/i, message: ChangePasswordErrorMessages.NEW_PASSWORD },
  { regex: /general password error/i, message: ChangePasswordErrorMessages.GENERIC },
  { regex: /the user is a web service user/i, message: SendOTCErrorMessages.WEB_SERVICE_USER },
];

const ErrorMappings404: ErrorMapping[] = [];

const ErrorCodeMappings: ErrorMapping[] = [
  {
    regex: new RegExp(ValidationErrorCode.PASSWORDINVALID),
    message: ChangePasswordErrorMessages.NEW_PASSWORD,
  },
  {
    regex: new RegExp(ValidationErrorCode.PASSWORDDUPLICATE),
    message: ChangePasswordErrorMessages.DUPLICATE_PASSWORD,
  },
  {
    regex: new RegExp(ValidationErrorCode.PASSWORDMISMATCH),
    message: ChangePasswordErrorMessages.CURRENT_PASSWORD,
  },
  {
    regex: new RegExp(ValidationErrorCode.PASSWORDMINIMUMNOTEXCEEDED),
    message: ChangePasswordErrorMessages.PASSWORD_LIFESPAN,
  },
  {
    regex: new RegExp(SystemErrorCode.PASSWORDSYSTEMERROR),
    message: ChangePasswordErrorMessages.INTERNAL_ERROR,
  },
  {
    regex: new RegExp(ValidationErrorCode.GENERALVALIDATION),
    message: ChangePasswordErrorMessages.PASSWORD_LENGTH,
  },
  {
    regex: new RegExp(SystemErrorCode.GENERALSYSTEMERROR),
    message: ServerErrorMessages.GENERIC,
  },
  {
    regex: new RegExp(ValidationErrorCode.USERNOTAUTHORIZEDFORAPP),
    message: ServerErrorMessages.INVALID_APP,
  },
];

type SingleErrors = ServerError | NotFoundError;
function isErrorList(error: BadRequestErrors | SingleErrors): error is BadRequestErrors {
  return (error as BadRequestErrors).errorList !== undefined;
}
function isNotFound(error: SingleErrors): error is NotFoundError {
  return (error as NotFoundError).field !== undefined;
}
function isErrorCode(error: BadRequestErrors | SingleErrors): error is BadRequestErrors {
  // `GENERAL_VALIDATION` error code is re-used by several scenarios, so
  // disregard that as an error code so we key off of messages instead.
  return (error as BadRequestErrors).errorList?.every(
    (err) => err.code !== undefined && err.code !== 'GENERAL_VALIDATION',
  );
}

/**
 * Maps error messages or codes from API responses that are returned from the User Service Client
 * to more user-friendly messages the UI can render. Error codes are checked first with fall back to
 * checking error messages themselves.
 * @TODO remove checking error messages when API supports error codes for all responses.
 * @param errorReponse
 * @param override401 flag to override normal 401 error for login page
 *
 * The following checks occur:
 *  if list of errors (status code 400), check messages to see which field is causing the error
 *  else if NotFoundError (status code 404), check if user not found
 *  else return server error (status code 500)
 */
export default async function mapErrors(errorResponse: Response, override401?: boolean): Promise<string> {
  let mappedErrors = '';

  try {
    const errors = (await errorResponse.json()) as BadRequestError;
    if (isErrorCode(errors)) {
      errors.errorList.forEach((error: BadRequestError) => {
        let matchFound = false;
        ErrorCodeMappings.forEach((mapping) => {
          // Guaranteed that error.code is defined if we get here,
          // but TS doesn't realize that
          if (mapping.regex.test(error.code || '')) {
            matchFound = true;
            mappedErrors += mapping.message;
          }
        });
        // If we don't find a match for the error code, check the error message mappings
        if (!matchFound) {
          ErrorMappings400.forEach((messageMap) => {
            if (messageMap.regex.test(error.message)) {
              mappedErrors += messageMap.message;
            }
          });
        }
      });
    } else if (isErrorList(errors)) {
      errors.errorList.forEach((error: BadRequestError) => {
        ErrorMappings400.forEach((mapping) => {
          if (mapping.regex.test(error.message)) {
            mappedErrors += mapping.message;
          }
        });
      });
    } else if (isNotFound(errors)) {
      ErrorMappings404.forEach((mapping) => {
        if (mapping.regex.test(errors.message)) {
          mappedErrors += mapping.message;
        }
      });
      if (mappedErrors === '') {
        mappedErrors += ServerErrorMessages.GENERIC;
      } // set generic error message if none mapped
    }
  } catch {
    // if no request body exists, can either be 401 or server error
    // if (errorResponse.status === 401 && errorResponse.url.includes("oidc/authorize"))
    if (errorResponse.status === 401 && override401) {
      mappedErrors += LoginErrorMessages.FAILED;
    }
  }

  return mappedErrors === '' ? ServerErrorMessages.GENERIC : mappedErrors;
}
