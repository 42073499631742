import React from 'react';
import { ValidationList } from '@ver-uds/uswds-react';

interface PasswordRequirementProps {
  password: string;
  logonId: string;
  id: string;
  oldPassword?: string;
  checkConsecutiveChars?: boolean;
  setIsValid: (isValid: boolean) => void;
}

const VALID_SPECIAL_CHARS = '<>!@$%*()?:;{}+\\-~';

const numberRequirement = (password: string): boolean => {
  if (!password) {
    return false;
  }

  const first = password[0];
  const last = password[password.length - 1];
  const digit = /\d/;

  return !(digit.test(first) || digit.test(last)) && digit.test(password);
};

const consecutiveCharsRequirement = (password: string, oldPassword: string): boolean => {
  if (!password) {
    return false;
  }
  // If the old password isn't available yet, we can't say this is invalid.
  if (password && !oldPassword) {
    return true;
  }
  for (let i = 0; i < oldPassword.length - 3; i += 1) {
    const chars = oldPassword.substring(i, i + 3);
    if (password.includes(chars)) {
      return false;
    }
  }
  return true;
};

const PasswordRequirements = ({
  password = '',
  logonId,
  id = '',
  oldPassword = '',
  checkConsecutiveChars,
  setIsValid,
}: PasswordRequirementProps): JSX.Element => {
  const rules = [
    {
      name: 'At least 1 letter',
      matcher: (target: string): boolean => target?.length > 0 && /[A-Za-z]+/.test(target),
    },
    {
      name: 'At least 1 number, not as the first or last character',
      matcher: (target: string): boolean => numberRequirement(target),
    },
    {
      name: 'At least 1 special character from the following ! @ $ % * ( ) ? : ; { } + - ~ < >',
      matcher: (target: string): boolean => {
        const regex = new RegExp(`[${VALID_SPECIAL_CHARS}]`);
        return regex.test(target);
      },
    },
    {
      name: 'Does not contain an invalid special character',
      matcher: (target: string): boolean => {
        // matches the valid character list strictly: any other character will cause this to be false
        const regex = new RegExp(`^[${VALID_SPECIAL_CHARS}a-zA-Z0-9]+$`);
        return regex.test(target);
      },
    },
    {
      name: 'Not identical to the User ID',
      matcher: (target: string): boolean => target?.length > 0 && target !== logonId,
    },
    {
      name: 'Password length between 8 and 14 characters',
      matcher: (target: string): boolean => target?.length >= 8 && target?.length <= 14,
    },
  ];

  if (checkConsecutiveChars) {
    rules.push({
      name: 'No more than two consecutive characters from the prior password',
      matcher: (target: string): boolean => consecutiveCharsRequirement(target, oldPassword),
    });
  }

  return (
    <ValidationList
      id={id}
      title="Password Requirements"
      targetValue={password}
      items={rules}
      isValidCallback={setIsValid}
    />
  );
};

PasswordRequirements.defaultProps = {
  oldPassword: '',
  checkConsecutiveChars: false,
};

export default PasswordRequirements;
