// See https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
// for instrutions for how to control the included polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import '@ver-uds/styles';
import { Modal } from '@ver-uds/react';
import App from './App';

if (process.env.REACT_APP_MSW_ENABLED === 'TRUE') {
  // eslint-disable-next-line
  const worker = require('./mocks/browser').default;
  // eslint-disable-next-line
  worker.start(); // Use mocked api call
  // worker.stop(); // Use real api calls
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

Modal.setAppElement('#root');
