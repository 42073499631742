import React from 'react';
import { GlobalHeader } from '@ver-uds/react';
import { Client } from '../Providers/OAuthParamsParser';
import eveLogo from '../../images/eve-logo.png';
import eveLogo2x from '../../images/eve-logo@2x.png';
import eveLogo3x from '../../images/eve-logo@3x.png';

import saveLogo from '../../images/save-logo.png';
import saveLogo2x from '../../images/save-logo@2x.png';
import saveLogo3x from '../../images/save-logo@3x.png';

const Header = ({ appName: app }: { appName: Client }): JSX.Element => {
  let logo = null;

  if (app === Client.SAVE) {
    logo = (
      <img
        src={saveLogo}
        srcSet={`${saveLogo2x} 2x, ${saveLogo3x} 3x`}
        alt="Systematic Alien Verification for Entitlements (SAVE)"
      />
    );
  }

  if (app === Client.EVERIFY) {
    logo = <img src={eveLogo} srcSet={`${eveLogo2x} 2x, ${eveLogo3x} 3x`} alt="E-Verify" />;
  }

  return <GlobalHeader logo={logo} />;
};

export default Header;
