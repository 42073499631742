export enum EnrollmentState {
  INITIAL,
  TERMS_ACCEPTED,
  ENROLLMENT_ACCEPTED,
}

export type UserAccountForm = {
  emailAddress: string;
  lastName: string;
  firstName: string;
  middleInitial: string;
  phoneNumber: string;
};

export type SaveUserAccountForm = UserAccountForm & { confirmEmail: string };
