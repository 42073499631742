import React from 'react';
import { PageHeader, Table } from '@ver-uds/react';
import { Helmet } from 'react-helmet-async';
import { Column } from 'react-table';
import { Feature, useFeatureFlags } from '../components/FeatureFlags/FeatureFlagsContext';

const FeatureFlags = (): JSX.Element => {
  const featureFlags = useFeatureFlags();

  type TableData = {
    name: string;
    status: string;
  };

  const tableColumns: Column<TableData>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
  ];

  const featureFlagsStatus: TableData[] = Object.keys(featureFlags).map((flag) => {
    return {
      name: flag,
      status: featureFlags?.[flag as Feature]?.toString(),
    };
  });

  return (
    <>
      <Helmet>
        <title>Feature Flags Status</title>
      </Helmet>
      <PageHeader>Feature Flags Status</PageHeader>
      <section className="grid-container usa-section padding-top-4">
        {featureFlags.showFeatureFlagsStatus && (
          <Table
            id="feature-flags-status-table"
            aria-label="feature flags status table"
            columns={tableColumns}
            data={featureFlagsStatus}
          />
        )}
      </section>
    </>
  );
};

export default FeatureFlags;
