import { Button } from '@ver-uds/react';
import { Alert, TextInputControl } from '@ver-uds/uswds-react';
import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { emailValidationRegex } from '../../pages/forgot-userid';
import { UserAccountForm } from './constants';

export const NewUserAccountEve = ({
  formMethods,
  onSubmit,
  submissionError,
  isLoading,
}: {
  formMethods: UseFormReturn<UserAccountForm>;
  onSubmit: SubmitHandler<UserAccountForm>;
  submissionError: boolean;
  isLoading: boolean;
}): JSX.Element => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = formMethods;

  return (
    <section>
      <h1>Enrollment Point of Contact</h1>
      <p className="usa-intro">
        The Enrollment Point of Contact is responsible for managing the E-Verify enrollment process for your company.
        Please submit your email address to create your secure Enrollment Point of Contact account.
      </p>
      <Alert status="info">
        <Alert.Text>
          If your company already has an E-Verify account do not enroll again. Contact your account administrator if you
          need to create a new user account or reactivate an existing account.
        </Alert.Text>
      </Alert>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form id="new-user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextInputControl
          id="email-address"
          label="Email Address"
          className="grid-col-9 tablet:grid-col-3"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('emailAddress', {
            required: 'Email address is required.',
            pattern: {
              value: emailValidationRegex,
              message: 'Please enter a valid email address.',
            },
          })}
          errorId="email-address-error"
          errorMessage={errors?.emailAddress?.message}
        />
        <p>
          Questions? We&apos;re here to help! Email E-Verify support at{' '}
          <a
            href="mailto:E-Verify@uscis.dhs.gov"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Send an email to E-Verify@uscis.dhs.gov"
          >
            E-Verify@uscis.dhs.gov
          </a>
          .
        </p>
        {submissionError && (
          <Alert status="error">
            <Alert.Text>
              Your Enrollment Point of Contact account is unable to be created at this time. Please wait a few minutes
              and try again.
            </Alert.Text>
          </Alert>
        )}
        <div className="padding-y-4 display-flex flex-row flex-align-center">
          <hr className="width-full" />
        </div>
        <Button form="new-user-form" type="submit" variant="submit" disabled={isLoading}>
          Submit
        </Button>
      </form>
    </section>
  );
};

export default NewUserAccountEve;
