import React, { ReactNode } from 'react';

export interface DHSFormWrapperProps {
  children?: ReactNode;
}

const DHSFormWrapper = ({ children }: DHSFormWrapperProps): JSX.Element => {
  return (
    <section className="margin-x-auto grid-col padding-x-105 tablet:grid-col-7 desktop:grid-col-6 border border-base radius-md bg-dhs-seal bg-dhs-seal--page bg-position-y-0">
      <div className="margin-y-7 margin-x-3 padding-x-4 tablet:padding-x-3 tablet-lg:padding-x-6 desktop:padding-x-9">
        {children}
      </div>
    </section>
  );
};

export default DHSFormWrapper;
