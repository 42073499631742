import React from 'react';
import { Banner, BannerExtension, SkipNav } from '@ver-uds/uswds-react';
import styled from 'styled-components';
import { FooterComponent as Footer } from './Footer/Footer';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const ErrorFallback: React.FC = ({ children }) => (
  <FlexContainer>
    <SkipNav.Anchor />
    <Banner extensionType={BannerExtension.GOV} />
    <SkipNav.MainContent className="width-full flex-fill">{children}</SkipNav.MainContent>
    <Footer />
  </FlexContainer>
);

export default ErrorFallback;
