import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { UserStatus } from '@vis-auth/vis-user-client';
import { legacyIdpApi } from '../userService';
import ChangePasswordForm from '../components/ChangePasswordForm/ChangePasswordForm';
import { useUserData, UserData } from '../components/Providers/UserDataProvider';
import mapErrors from '../map-errors';

async function changePasswordPut({
  userId,
  currentPassword,
  newPassword,
  authorizationCode,
  params,
}: {
  userId: string;
  currentPassword?: string;
  newPassword: string;
  authorizationCode: string;
  params: UserData;
}): Promise<string> {
  const { state, redirect_uri: redirectUri } = params;

  let passwordChangeBody;
  if (currentPassword) {
    passwordChangeBody = { currentPassword, newPassword };
  } else {
    passwordChangeBody = { newPassword };
  }

  try {
    await legacyIdpApi.updatePassword({
      id: userId,
      passwordChangeBody,
    });

    return `${redirectUri}?code=${authorizationCode}&state=${state}`;
  } catch (err) {
    // The error caught is a Response type object, not an Error. Re-throw a true Error.
    throw new Error(await mapErrors(err as Response));
  }
}

const ChangePassword = (): JSX.Element => {
  let errorMessage = '';
  let title;
  let subtitle;
  let instructions;
  let submitButtonText = 'Continue';
  const cancelButtonText = 'Cancel';
  let currentPasswordRequired = true;

  const userData = useUserData();
  const history = useHistory();

  const { userId, logonId, authorizationCode, userStatus } = userData;

  if (userStatus === UserStatus.PASSWORDCHANGEREQUIRED) {
    title = 'Password Expired';
    subtitle = 'Choose new password';
    instructions = 'Your password has expired. Please enter your current password and choose a new one.';
  } else {
    title = 'Reset Password';
    subtitle = 'Choose New Password';
    submitButtonText = 'Change Password';
    currentPasswordRequired = false;
  }

  const [mutate, { status, error }] = useMutation(changePasswordPut, {
    onSuccess: () => {
      // history.push without leading slash will append to current path
      return history.push('password-confirmation');
    },
  });

  if (!userId || !authorizationCode) {
    throw new Error('User ID or authorization code missing.');
  }

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  const onSubmit = async ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<void> => {
    await mutate({
      userId,
      currentPassword,
      newPassword,
      authorizationCode,
      params: userData,
    });
  };

  const onCancel = (): void => {
    return history.push('/');
  };

  return (
    <ChangePasswordForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitBtnText={submitButtonText}
      cancelBtnText={cancelButtonText}
      status={status}
      helmet={title}
      title={title}
      subtitle={subtitle}
      instructions={instructions}
      currentPassRequired={currentPasswordRequired}
      currentPassLabelHint="(required)"
      newPassLabelHint="(required)"
      confirmPassLabelHint="(required)"
      errorMessage={errorMessage}
      logonId={logonId}
    />
  );
};

export default ChangePassword;
