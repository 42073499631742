import { Button, ButtonGroup, Modal } from '@ver-uds/react';
import { Alert } from '@ver-uds/uswds-react';
import React from 'react';

export default function EnrollmentInpProccessModal({
  open,
  close,
  sendConfirmation,
  error,
  isLoading,
}: {
  open: boolean;
  close: VoidFunction;
  sendConfirmation: VoidFunction;
  error?: string;
  isLoading: boolean;
}) {
  return (
    <Modal
      id="enrollment-in-process-modal"
      open={open}
      header="Enrollment in Process"
      hasDismissButton={false}
      close={close}
    >
      <Modal.Content>
        <div className="padding-bottom-4">
          <p>
            An enrollment associated with this email is already in-process. Click the button below to request a new
            confirmation email to confirm your account and continue your enrollment.
          </p>
          {error ? (
            <Alert status="error">
              <Alert.Text>{error}</Alert.Text>
            </Alert>
          ) : null}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <ButtonGroup>
          <Button type="button" variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={sendConfirmation} disabled={isLoading}>
            Send Confirmation Email
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}
