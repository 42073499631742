import React, { ReactNode } from 'react';
import { Footer as VerUdsFooter, Button } from '@ver-uds/react';
import { Client } from '../Providers/OAuthParamsParser';
import ssaLogo from '../../images/ssa-seal.png';
import ssaLogo2x from '../../images/ssa-seal@2x.png';
import ssaLogo3x from '../../images/ssa-seal@3x.png';
import dhsSeal from '../../images/dhs-seal.svg';

const ACCESSIBILITY_URL = 'https://www.uscis.gov/website-policies/accessibility';
const VIEWERS_URL = 'https://www.uscis.gov/website-policies/plug-ins';

export interface FooterProps {
  FEEDBACK_URL?: string;
  SsaSeal?: ReactNode;
}

export const FooterComponent = ({ FEEDBACK_URL, SsaSeal }: FooterProps): JSX.Element => {
  return (
    <VerUdsFooter
      primarySection={
        <VerUdsFooter.Section
          leftSection={
            <VerUdsFooter.Nav>
              <a href="https://www.dhs.gov/" target="_blank" rel="noopener noreferrer">
                U.S. Department of Homeland Security
              </a>
              <a href="https://www.uscis.gov/" target="_blank" rel="noopener noreferrer">
                U.S. Citizenship and Immigration Services
              </a>
            </VerUdsFooter.Nav>
          }
          rightSection={
            <VerUdsFooter.Nav>
              <a href={ACCESSIBILITY_URL} target="_blank" rel="noopener noreferrer">
                Accessibility Statement
              </a>
              <a href={VIEWERS_URL} target="_blank" rel="noopener noreferrer">
                Viewer Software
              </a>
            </VerUdsFooter.Nav>
          }
        />
      }
      secondarySection={
        <VerUdsFooter.Section
          leftSection={
            <VerUdsFooter.Nav>
              <img src={dhsSeal} alt="Department of Homeland Security (DHS) Seal" />
              {SsaSeal}
            </VerUdsFooter.Nav>
          }
          rightSection={
            <VerUdsFooter.Nav>
              {FEEDBACK_URL && (
                <Button type="button" href={FEEDBACK_URL} as="a">
                  Provide Feedback
                </Button>
              )}
            </VerUdsFooter.Nav>
          }
        />
      }
    />
  );
};

const FooterContainer = ({ appName: app }: { appName?: Client }): JSX.Element => {
  let FEEDBACK_URL;
  let SsaSeal;

  if (app === Client.SAVE) {
    FEEDBACK_URL = 'mailto:savesystemfeedback@uscis.dhs.gov';
  }
  if (app === Client.EVERIFY) {
    FEEDBACK_URL = 'mailto:E-Verify@uscis.dhs.gov';
    SsaSeal = (
      <img src={ssaLogo} srcSet={`${ssaLogo2x} 2x, ${ssaLogo3x} 3x`} alt="Social Security Administration (SSA) Seal" />
    );
  }
  return <FooterComponent FEEDBACK_URL={FEEDBACK_URL} SsaSeal={SsaSeal} />;
};

export default FooterContainer;
