import { Button } from '@ver-uds/react';
import { Alert, TextInputControl } from '@ver-uds/uswds-react';
import React, { useEffect, useRef } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { emailValidationRegex } from '../../pages/forgot-userid';
import { useEnvironment } from '../Environment/EnvironmentContext';
import { SaveUserAccountForm } from './constants';

export const NewUserAccountSave = ({
  formMethods,
  onSubmit,
  submissionError,
}: {
  formMethods: UseFormReturn<SaveUserAccountForm>;
  onSubmit: SubmitHandler<SaveUserAccountForm>;
  submissionError: boolean;
}): JSX.Element => {
  const env = useEnvironment();
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = formMethods;

  const alertRef = useRef<HTMLDivElement>(null);
  const email = watch('emailAddress');

  useEffect(() => {
    alertRef?.current?.focus();
  }, [submissionError]);

  return (
    <section>
      <Alert status="info">
        <Alert.Heading>Already have a SAVE Login?</Alert.Heading>
        <Alert.Text>
          Already have an account or forgot your password?{' '}
          <a href={env.SAVE_ENROLLMENT_REDIRECT} rel="noopener">
            Proceed to the login page.
          </a>
        </Alert.Text>
      </Alert>
      <h1>Create New Account</h1>
      <p className="usa-intro">
        To get started enter the contact information of a person who will be responsible for managing the SAVE
        enrollment process. After creating an account and validating your email address, you will be able to sign in to
        the system and manage the remainder of the enrollment.
      </p>
      {submissionError && (
        <div ref={alertRef}>
          <Alert status="error">
            <Alert.Text>An error occurred and the user was not saved. Please try again.</Alert.Text>
          </Alert>
        </div>
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form id="new-user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextInputControl
          id="first-name"
          label="First Name"
          className="grid-col-9 tablet:grid-col-3"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('firstName', {
            required: 'First name is required.',
            maxLength: {
              value: 40,
              message: 'Please enter no more than 40 characters.',
            },
          })}
          errorId="first-name-error"
          errorMessage={errors?.firstName?.message}
        />
        <TextInputControl
          id="last-name"
          label="Last Name"
          className="grid-col-9 tablet:grid-col-3"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('lastName', {
            required: 'Last name is required.',
            maxLength: {
              value: 40,
              message: 'Please enter no more than 40 characters.',
            },
          })}
          errorId="last-name-error"
          errorMessage={errors?.lastName?.message}
        />
        <TextInputControl
          id="middle-initial"
          label="Middle Initial"
          className="grid-col-3 tablet:grid-col-1"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('middleInitial', {
            maxLength: {
              value: 1,
              message: 'Please enter no more than one character.',
            },
          })}
          errorId="middle-initial-error"
          errorMessage={errors?.middleInitial?.message}
        />
        <TextInputControl
          id="phone-number"
          label="Phone Number"
          className="grid-col-9 tablet:grid-col-3"
          hint="10 numbers only, no country code"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('phoneNumber', {
            required: 'Phone number is required.',
            pattern: {
              value: /^\d{10}$/,
              message: 'Please enter a ten digit phone number without symbols or spaces.',
            },
          })}
          errorId="phone-number-error"
          errorMessage={errors?.phoneNumber?.message}
        />
        <TextInputControl
          id="email-address"
          label="Email Address"
          className="grid-col-9 tablet:grid-col-3"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('emailAddress', {
            required: 'Email address is required.',
            pattern: {
              value: emailValidationRegex,
              message: 'Please enter a valid email address.',
            },
          })}
          errorId="email-address-error"
          errorMessage={errors?.emailAddress?.message}
        />
        <TextInputControl
          id="confirm-email-address"
          label="Confirm Email Address"
          className="grid-col-9 tablet:grid-col-3"
          required
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('confirmEmail', {
            required: 'Confirm email address is required.',
            validate: (value) => value === email || 'Email addresses do not match.',
          })}
          errorId="confirm-email-error"
          errorMessage={errors?.confirmEmail?.message}
        />
        <div className="padding-y-4 display-flex flex-row flex-align-center">
          <hr className="width-full" />
        </div>
        <Button type="submit">Register</Button>
      </form>
    </section>
  );
};

export default NewUserAccountSave;
