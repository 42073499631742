import { Button, PageLoader } from '@ver-uds/react';
import { Alert } from '@ver-uds/uswds-react';
import { Application } from '@vis-auth/vis-user-client';
import React, { useReducer, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmationRequired } from '../../components/Enrollment/ConfirmationRequired';
import EmailSentSave from '../../components/Enrollment/EmailSentSave';
import { useEnvironment } from '../../components/Environment/EnvironmentContext';
import {
  mapError,
  RenewalRequestApplicationEnum,
  useRenewRegistration,
  useVerifyOtc,
  ValidationError,
  ValidationErrorCode,
} from '../../registrationService';

const RegistrationVerify = ({ app }: { app: Application }): JSX.Element => {
  const env = useEnvironment();
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState<ValidationError | null>(null);
  const [renewalError, setRenewalError] = useState<string | null>(null);
  const [showSaveSuccess, toggleSaveSuccess] = useReducer(() => true, false);
  const [showEveSuccess, toggleEveSuccess] = useReducer(() => true, false);
  useVerifyOtc(
    { otc: code },
    {
      retry: false,
      onSuccess: () => {
        window.location.assign(
          app === Application.EVERIFY ? env.EVE_ENROLLMENT_REDIRECT : env.SAVE_ENROLLMENT_REDIRECT,
        );
      },
      onError: (apiError) => {
        if (
          [ValidationErrorCode.OTCALREADYVERIFIED, ValidationErrorCode.OTCNOTPRESENT].includes(
            apiError?.code as ValidationErrorCode,
          )
        ) {
          window.location.assign(
            app === Application.EVERIFY ? env.EVE_ENROLLMENT_REDIRECT : env.SAVE_ENROLLMENT_REDIRECT,
          );
          return;
        }
        if (apiError?.code === ValidationErrorCode.OTCTIMEOUT) {
          setError(apiError);
          return;
        }
        history.replace('/error');
      },
    },
  );
  const [mutate, { isLoading }] = useRenewRegistration({
    onSuccess: () => {
      if (app === Application.SAVE) {
        toggleSaveSuccess();
      }
      if (app === Application.EVERIFY) {
        toggleEveSuccess();
      }
    },
    onError: (apiError) => {
      setRenewalError(mapError(apiError));
    },
  });

  if (showEveSuccess) {
    return (
      <>
        <Helmet>
          <title>Confirmation Required</title>
        </Helmet>
        <ConfirmationRequired />
      </>
    );
  }

  if (error?.code === ValidationErrorCode.OTCTIMEOUT) {
    return (
      <>
        <Helmet>
          <title>Email Confirmation</title>
        </Helmet>
        <div>
          <h1>Email Confirmation</h1>
          <Alert status="error">
            <Alert.Heading>Verification Code Expired</Alert.Heading>
            <Alert.Text>{renewalError || mapError(error)}</Alert.Text>
          </Alert>
          <Button
            className="margin-top-4"
            disabled={isLoading}
            onClick={() => {
              // onError and onSuccess handled above
              // eslint-disable-next-line no-void
              void mutate({
                renewalRequest: {
                  otc: code,
                  application: app as unknown as RenewalRequestApplicationEnum, // :(
                },
              });
            }}
          >
            Send Confirmation Email
          </Button>
        </div>

        <EmailSentSave open={showSaveSuccess} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Verifying Email Address</title>
      </Helmet>
      <PageLoader label="Verifying Email Address" />
    </>
  );
};

export default RegistrationVerify;
