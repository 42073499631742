import { PageLoader } from '@ver-uds/react';
import {
  RegistrationRequest,
  RegistrationRequestApplicationEnum,
  RenewalRequestApplicationEnum,
  ServerError,
  ValidationError,
  ValidationErrorCode,
} from '@vis-auth/vis-registration-client';
import { Application } from '@vis-auth/vis-user-client';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ConfirmationRequired } from '../../components/Enrollment/ConfirmationRequired';
import { EnrollmentState, UserAccountForm } from '../../components/Enrollment/constants';
import EnrollmentInProccessModal from '../../components/Enrollment/EnrollmentInProccessModal';
import { NewUserAccountEve } from '../../components/Enrollment/NewUserAccountEve';
import { TermsOfUse } from '../../components/Enrollment/TermsOfUse';
import { mapError, useRegisterUser, useRenewRegistration } from '../../registrationService';

const EveEnroll = (): JSX.Element => {
  const [enrollmentStatus, setEnrollmentStatus] = useState<EnrollmentState | undefined>(undefined);
  const formMethods = useForm<UserAccountForm>({});
  const { watch } = formMethods;
  const email = watch('emailAddress');
  const [enrollError, setEnrollError] = useState<ServerError | ValidationError | null>(null);
  const [renewError, setRenewError] = useState<ServerError | ValidationError | null>(null);

  const [mutate, { isLoading: isLoadingRegister }] = useRegisterUser({
    onSuccess: () => {
      setEnrollmentStatus(EnrollmentState.ENROLLMENT_ACCEPTED);
    },
    onError(error) {
      setEnrollError(error);
    },
  });

  const [renewRegistration, { isLoading: isLoadingRenew }] = useRenewRegistration({
    onSuccess: () => {
      setEnrollmentStatus(EnrollmentState.ENROLLMENT_ACCEPTED);
    },
    onError(error) {
      setRenewError(error);
    },
  });

  const isLoading = isLoadingRegister || isLoadingRenew;

  const onSubmit: SubmitHandler<UserAccountForm> = async (data: UserAccountForm): Promise<void> => {
    setEnrollError(null);
    await mutate({
      application: RegistrationRequestApplicationEnum.EVERIFY,
      email: data.emailAddress,
    } as RegistrationRequest);
  };

  const showInProgressModal = enrollError?.code === ValidationErrorCode.OTCPENDING;

  if (enrollmentStatus === EnrollmentState.TERMS_ACCEPTED) {
    return (
      <>
        <Helmet>
          <title>Create New User Account</title>
        </Helmet>
        <NewUserAccountEve
          formMethods={formMethods}
          onSubmit={onSubmit}
          submissionError={!showInProgressModal && enrollError !== null}
          isLoading={isLoading}
        />
        <EnrollmentInProccessModal
          open={showInProgressModal}
          close={() => {
            setRenewError(null);
            setEnrollError(null);
          }}
          error={renewError ? mapError(renewError) : ''}
          isLoading={isLoading}
          sendConfirmation={() => {
            setRenewError(null);
            // eslint-disable-next-line no-void
            void renewRegistration({
              renewalRequest: {
                application: RenewalRequestApplicationEnum.EVERIFY,
                email,
              },
            });
          }}
        />
        {isLoading && <PageLoader label="Loading." id="submit-poc-loading" />}
      </>
    );
  }

  if (enrollmentStatus === EnrollmentState.ENROLLMENT_ACCEPTED) {
    return (
      <>
        <Helmet>
          <title>Confirmation Required</title>
        </Helmet>
        <ConfirmationRequired email={email} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Use Agreement</title>
      </Helmet>
      <TermsOfUse
        app={Application.EVERIFY}
        setTermsAccepted={() => setEnrollmentStatus(EnrollmentState.TERMS_ACCEPTED)}
      />
    </>
  );
};

export default EveEnroll;
