import { ForgotLogonIdRequest, UserTypeEnum } from '@vis-auth/vis-user-client';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert, ButtonGroup, Fieldset, Legend, Submit, TextInputControl } from '@ver-uds/uswds-react';
import DHSFormWrapper from '../components/DHSFormWrapper/DHSFormWrapper';
import { useUserData } from '../components/Providers/UserDataProvider';
import { legacyIdpApi } from '../userService';
import { ServerErrorMessages } from '../map-errors';

const NOT_FOUND_MESSAGE = 'There is no account tied to the email.';

export const emailValidationRegex =
  /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

const SuccessMessage = (): JSX.Element => {
  return (
    <>
      <Alert status="success" className="margin-bottom-1">
        <Alert.Heading>Success</Alert.Heading>
        <Alert.Text>Your User ID has been sent to your email address. Go back to Sign In.</Alert.Text>
      </Alert>
      <Link to="/" className="usa-button">
        Back to Sign In
      </Link>
    </>
  );
};

const ErrorMessage = ({ message }: { message: string }): JSX.Element => {
  return (
    <Alert status="error">
      <Alert.Heading>Error</Alert.Heading>
      <Alert.Text>{message}</Alert.Text>
    </Alert>
  );
};

const ForgotUserId = (): JSX.Element => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { client } = useUserData();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<{ email: string }>({
    reValidateMode: 'onSubmit',
  });

  const email = watch('email');

  return (
    <>
      <Helmet>
        <title>Forgot User ID</title>
      </Helmet>
      <DHSFormWrapper>
        <form
          noValidate
          aria-label="Reset User ID Form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(async () => {
            const request: ForgotLogonIdRequest = { email, app: client as UserTypeEnum };

            try {
              await legacyIdpApi.forgotLogonId(request);
              setSuccess(true);
            } catch (response) {
              setSuccess(false);
              setError((response as Response).status === 404 ? NOT_FOUND_MESSAGE : ServerErrorMessages.GENERIC);
            }
          })}
        >
          <Fieldset>
            <Legend>
              <h2>Forgot User ID</h2>
            </Legend>
            {success ? <SuccessMessage /> : null}
            {error && !success ? <ErrorMessage message={error} /> : null}
            {!success ? (
              <>
                <p>Enter the email address associated with your account and we will send your User ID.</p>
                <TextInputControl
                  id="email"
                  type="email"
                  label="Email Address"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: emailValidationRegex,
                      message: 'The provided email is of an invalid format',
                    },
                  })}
                  errorId="email-error"
                  errorMessage={errors.email && errors.email.message}
                />
                <ButtonGroup className="margin-top-4">
                  <Link to="/" className="usa-button--outline usa-button">
                    Cancel
                  </Link>
                  <Submit value="Submit" />
                </ButtonGroup>
              </>
            ) : null}
          </Fieldset>
        </form>
      </DHSFormWrapper>
    </>
  );
};

export default ForgotUserId;
