import React from 'react';
import { Alert } from '@ver-uds/uswds-react';
import AlertForm from '../components/AlertForm/AlertForm';
import { useUserData } from '../components/Providers/UserDataProvider';

const ResetPasswordConfirmation = (): JSX.Element => {
  // eslint-disable-next-line camelcase
  const { redirect_uri, authorizationCode, state } = useUserData();
  const alert = (
    <Alert status="success" noIcon slim>
      <Alert.Text>Your password has been successfully changed. Please continue.</Alert.Text>
    </Alert>
  );

  return (
    <AlertForm
      helmet="Reset Password Confirmation"
      title="Reset Password"
      alert={alert}
      continueBtnText="Continue"
      // eslint-disable-next-line camelcase
      continueBtnUrl={`${redirect_uri}?code=${authorizationCode || ''}&state=${state}`}
    />
  );
};

export default ResetPasswordConfirmation;
