/* eslint-disable camelcase */
import { AuthorizeCodeChallengeMethodEnum, ValidateAnswersCodeChallengeMethodEnum } from '@vis-auth/vis-user-client';
import EnvironmentVariables from '../Environment/EnvironmentVariables';

export enum Client {
  EVERIFY = 'EVERIFY',
  SAVE = 'SAVE',
  UNKNOWN = 'UNKNOWN',
}

export type ParsedSearchParams = {
  client_id: string;
  client: string;
  code_challenge: string;
  code_challenge_method: AuthorizeCodeChallengeMethodEnum | ValidateAnswersCodeChallengeMethodEnum;
  state: string;
  scope: string;
  redirect_uri: string;
  forgot_user_id_url: string;
};

type ParsedSearchParamsError = {
  error: Error;
};

export type ParsedSearchParamsResult = ParsedSearchParams | ParsedSearchParamsError;

export function parseSearchParams(queryString: string, env: EnvironmentVariables): ParsedSearchParamsResult {
  let queryStringToParse = queryString;

  if (!queryString.length && env.ENV === 'local') {
    const storedParams = localStorage.getItem('params');

    if (storedParams) {
      queryStringToParse = storedParams;
    }
  }

  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(queryStringToParse);

  const client_id = searchParams.get('client_id');

  let client;
  let forgot_user_id_url;

  if (env.SAVE_CLIENT_ID.includes(String(client_id))) {
    client = Client.SAVE;
    forgot_user_id_url = env.SAVE_FORGOT_USER_ID;
  } else if (env.EVERIFY_CLIENT_ID.includes(String(client_id))) {
    client = Client.EVERIFY;
    forgot_user_id_url = env.EVERIFY_FORGOT_USER_ID;
  } else {
    client = Client.UNKNOWN;
  }

  const parsedParams = {
    client_id,
    client,
    forgot_user_id_url,
    code_challenge: searchParams.get('code_challenge'),
    code_challenge_method: searchParams.get('code_challenge_method'),
    state: searchParams.get('state'),
    scope: searchParams.get('scope'),
    redirect_uri: searchParams.get('redirect_uri'),
  };
  const missingParams = [];
  if (!parsedParams.client_id) {
    missingParams.push('client_id');
  }
  if (!parsedParams.code_challenge) {
    missingParams.push('code_challenge');
  }
  if (!parsedParams.code_challenge_method) {
    missingParams.push('code_challenge_method');
  }
  if (!parsedParams.state) {
    missingParams.push('state');
  }
  if (!parsedParams.scope) {
    missingParams.push('scope');
  }
  if (!parsedParams.redirect_uri) {
    missingParams.push('redirect_uri');
  }
  if (!parsedParams.forgot_user_id_url) {
    missingParams.push('forgot_user_id_url');
  }
  if (missingParams.length) {
    const error = new Error(`Missing url param(s): ${missingParams.join(', ')}`);
    return { error };
  }

  if (queryString.length && env.ENV === 'local') {
    localStorage.setItem('params', queryString);
  }

  return parsedParams as ParsedSearchParams;
}
