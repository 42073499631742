import React, { useContext, useEffect, useState, FunctionComponent } from 'react';
import EnvironmentVariables from './EnvironmentVariables';

const ENV_VARIABLES_FILE_PATH = `${process.env.PUBLIC_URL}/env.json`;

const EnvironmentContext = React.createContext<EnvironmentVariables | null>(null);

export const useEnvironment = (): EnvironmentVariables => {
  const environmentVariables = useContext(EnvironmentContext);
  if (!environmentVariables) {
    throw new Error('useEnvironment must be used inside EnvironmentProvider');
  }
  return environmentVariables;
};

export const EnvironmentConsumer = EnvironmentContext.Consumer;

async function fetchEnvironmentContext(): Promise<EnvironmentVariables> {
  const response = await fetch(ENV_VARIABLES_FILE_PATH);
  const responseJson = (await response.json()) as EnvironmentVariables;
  return responseJson;
}

export const EnvironmentProvider: FunctionComponent = ({ children }) => {
  const [environmentVariables, setEnvironmentVariables] = useState<EnvironmentVariables | null>(null);

  useEffect(() => {
    let isMounted = true;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEnvironmentContext().then((responseJson) => {
      if (isMounted) {
        setEnvironmentVariables(responseJson);
      }
    });

    // clean up useEffect to avoid running after a component is unmounted
    // https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
    return (): void => {
      isMounted = false;
    };
  }, []);

  if (environmentVariables) {
    return <EnvironmentContext.Provider value={environmentVariables}>{children}</EnvironmentContext.Provider>;
  }

  return <> Loading Env... </>;
};
