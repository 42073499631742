import React from 'react';
import { Button } from '@ver-uds/uswds-react';
import { PageHeader } from '@ver-uds/react';
import { Helmet } from 'react-helmet-async';

const Error = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>General System Error</title>
      </Helmet>
      <PageHeader variant="error">General System Error</PageHeader>
      <section className="grid-container usa-section padding-top-4">
        <h2>Something went wrong</h2>
        <p>
          Legacy IDP encountered a problem and is unable to complete your request at this time. Please wait a few
          minutes and try again.
        </p>
        <Button
          variant="primary"
          onClick={(): void => {
            window.history.back();
          }}
        >
          Go back
        </Button>
      </section>
    </>
  );
};

export default Error;
