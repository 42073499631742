import {
  Configuration,
  RegistrationApi,
  RegistrationRequest,
  RenewRegistrationRequest,
  ValidationError,
  ValidationErrorCode,
  VerifyRegistrationRequest,
  ServerError,
  SystemErrorCode,
} from '@vis-auth/vis-registration-client';
import { MutationConfig, MutationResultPair, QueryConfig, useMutation, useQuery } from 'react-query';

export * from '@vis-auth/vis-registration-client';

export const registrationServiceBasePath = '/api/registration';

export const config = new Configuration({
  basePath: `${window.location.origin}${registrationServiceBasePath}`,
});

export const registrationApi = new RegistrationApi(config);

async function mapErrors(errorResponse: Response) {
  const errors = (await errorResponse.json()) as {
    errorList?: Array<ValidationError>;
    errors?: Array<ValidationError>;
  };
  if (errors.errors) {
    errors.errorList = errors.errors;
  }
  return errors as { errorList: Array<ValidationError> };
}

enum RegistrationErrorMessages {
  INTERNAL_ERROR = `An internal service error occurred. Wait a moment and try again. If the problem continues, please contact support.\r\n`,
  GENERAL_ERROR = `We encountered an error. Please wait a few minutes and try again.\r\n`,
  OTC_NOT_PRESENT = `We encountered an error. Please try the link in your email again.\r\n`,
  OTC_ALREADY_VERIFIED = `The email has already been verified.\r\n`,
  OTC_TIMEOUT = `The link you used has expired. Please click the button below to request a new confirmation email.\r\n`,
}

export function mapError(error: ValidationError | ServerError): string {
  switch (error.code) {
    case ValidationErrorCode.OTCTIMEOUT:
      return RegistrationErrorMessages.OTC_TIMEOUT;
    case ValidationErrorCode.OTCNOTPRESENT:
      return RegistrationErrorMessages.OTC_NOT_PRESENT;
    case ValidationErrorCode.OTCALREADYVERIFIED:
      return RegistrationErrorMessages.OTC_ALREADY_VERIFIED;
    case SystemErrorCode.EMAILSENDFAILURE:
    case SystemErrorCode.GENERALSYSTEMERROR:
      return RegistrationErrorMessages.INTERNAL_ERROR;
    case ValidationErrorCode.GENERALVALIDATION:
    default:
      return RegistrationErrorMessages.GENERAL_ERROR;
  }
}

const registerUser = async (request: RegistrationRequest): Promise<void> => {
  try {
    return await registrationApi.initiateRegistration({ registrationRequest: request });
  } catch (error) {
    if (error instanceof Response) {
      const errors = await mapErrors(error);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errors.errorList?.[0] ?? new Error('Unknow Error');
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      code: SystemErrorCode.GENERALSYSTEMERROR,
    };
  }
};

export const useRegisterUser = (
  options?: MutationConfig<void, ServerError | ValidationError>,
): MutationResultPair<void, ServerError | ValidationError, RegistrationRequest, unknown> => {
  return useMutation<void, ServerError | ValidationError, RegistrationRequest>(registerUser, options);
};

const verify = async (requestParameters: VerifyRegistrationRequest): Promise<void> => {
  try {
    return await registrationApi.verifyRegistration(requestParameters);
  } catch (error) {
    if (error instanceof Response) {
      const errors = await mapErrors(error);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errors.errorList?.[0] ?? new Error('Unknow Error');
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      code: SystemErrorCode.GENERALSYSTEMERROR,
    };
  }
};

export const useVerifyOtc = (
  requestParameters: VerifyRegistrationRequest,
  options?: QueryConfig<void, ServerError | ValidationError>,
) => {
  return useQuery<void, ServerError | ValidationError>('verify', () => verify(requestParameters), options);
};

const renewRegistration = async (requestParameters: RenewRegistrationRequest): Promise<void> => {
  try {
    return await registrationApi.renewRegistration(requestParameters);
  } catch (error) {
    if (error instanceof Response) {
      const errors = await mapErrors(error);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errors.errorList?.[0] ?? new Error('Unknow Error');
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      code: SystemErrorCode.GENERALSYSTEMERROR,
    };
  }
};

export const useRenewRegistration = (
  options?: MutationConfig<void, ServerError | ValidationError, RenewRegistrationRequest>,
) => {
  return useMutation<void, ServerError | ValidationError, RenewRegistrationRequest>(renewRegistration, options);
};
