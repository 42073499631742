import React, { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserHistory, History } from 'history';
import { Application } from '@vis-auth/vis-user-client';
import AppShell from './components/Layouts/AppShell';
import { EnvironmentProvider, useEnvironment } from './components/Environment/EnvironmentContext';
import { UserDataProvider, useUserData } from './components/Providers/UserDataProvider';
import ForgotPassword from './pages/forgot-password';
import Error from './pages/error';
import Login from './pages/login';
import ChangePassword from './pages/change-password';
import ErrorFallback from './components/ErrorFallback';
import PasswordExpireWarning from './pages/password-expire-warning';
import ResetPasswordConfirmation from './pages/reset-password-confirmation';
import ForgotUserId from './pages/forgot-userid';
import SaveEnroll from './pages/enroll/SaveEnroll';
import EveEnroll from './pages/enroll/EveEnroll';
import RegistrationVerify from './pages/verify/RegistrationVerify';
import { FeatureFlagsProvider, useFeatureFlags } from './components/FeatureFlags/FeatureFlagsContext';
import FeatureFlags from './pages/feature-flags';
import OtcMessageSentPage from './pages/otc/otc-message-sent-page';
import OtcVerify from './pages/otc/otc-verify';
import OtcChangePassword from './pages/otc/otc-change-password';
import SendOtc from './pages/otc/send-otc';

const RoutesComponent = (): JSX.Element => {
  const { client } = useUserData();
  const { showFeatureFlagsStatus } = useFeatureFlags();
  return (
    <Switch>
      {showFeatureFlagsStatus && <Route exact path="/feature-flags" render={() => <FeatureFlags />} />}
      <Route path="/error">
        <ErrorFallback>
          <Error />
        </ErrorFallback>
      </Route>
      <Route path="*">
        <Switch>
          <AppShell app={client}>
            <Route
              exact
              path={['/password-expired/change-password', '/password-expiring/reset-password']}
              render={() => <ChangePassword />}
            />
            <Route exact path="/password-expiring">
              <PasswordExpireWarning />
            </Route>
            <Route
              exact
              path={[
                '/forgot-password/user-id',
                '/forgot-password/change-password',
                '/account-locked/user-id',
                '/account-locked/deactivated',
                '/account-locked/change-password',
              ]}
              render={() => <ForgotPassword />}
            />
            <Route exact path={['/forgot-user-id']} render={() => <ForgotUserId />} />
            <Route
              exact
              path={[
                '/password-expired/password-confirmation',
                '/password-expiring/password-confirmation',
                '/account-locked/password-confirmation',
                '/forgot-password/password-confirmation',
              ]}
              render={() => <ResetPasswordConfirmation />}
            />
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="*">
              {/*
                All other routes can redirect to login because error handling there
                will either redirect to /error or the Error Boundary will show the error fallback.
              */}
              <Redirect to="/" />
            </Route>
          </AppShell>
        </Switch>
      </Route>
    </Switch>
  );
};

const IDPApp = (): JSX.Element => {
  return (
    <UserDataProvider>
      <RoutesComponent />
    </UserDataProvider>
  );
};

const OtcApp = (): JSX.Element | null => {
  return (
    <>
      <Route exact path={['/change-pw/verify']} render={() => <OtcVerify />} />
      <Route exact path={['/change-pw/change-password']} render={() => <OtcChangePassword />} />
      <Route exact path={['/change-pw/send-otc']} render={() => <SendOtc />} />
      <Route exact path={['/change-pw/otc-message-sent']} render={() => <OtcMessageSentPage />} />
    </>
  );
};

const EnrollmentApp = (): JSX.Element | null => {
  const env = useEnvironment();

  return (
    <Switch>
      {env.NEW_EVE_ENROLLMENT_ENABLED ? (
        <Route path={['/enroll/everify', '/verify/everify']}>
          <AppShell app="EVERIFY">
            <Switch>
              <Route exact path="/enroll/everify" render={() => <EveEnroll />} />
              <Route
                exact
                path="/verify/everify/:code"
                render={() => <RegistrationVerify app={Application.EVERIFY} />}
              />
            </Switch>
          </AppShell>
        </Route>
      ) : null}
      <Route path={['/enroll/save', '/verify/save']}>
        <AppShell app="SAVE">
          <Switch>
            <Route exact path="/enroll/save" render={() => <SaveEnroll />} />
            <Route exact path="/verify/save/:code" render={() => <RegistrationVerify app={Application.SAVE} />} />
          </Switch>
        </AppShell>
      </Route>
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export const Routes = (): JSX.Element => {
  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback>
          <Error />
        </ErrorFallback>
      }
    >
      <Switch>
        <Route path={['/change-pw']} render={() => <OtcApp />} />
        <Route path={['/enroll', '/verify']} render={() => <EnrollmentApp />} />
        <Route path="/" render={() => <IDPApp />} />
      </Switch>
    </ErrorBoundary>
  );
};

export const AppProviders = ({
  children,
  history,
  queryConfig,
}: {
  children: ReactNode;
  history: History;
  queryConfig: ReactQueryConfig;
}): JSX.Element => {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <EnvironmentProvider>
        <FeatureFlagsProvider>
          <HelmetProvider>
            <div className="app">
              <Router history={history}>{children}</Router>
            </div>
          </HelmetProvider>
        </FeatureFlagsProvider>
      </EnvironmentProvider>
    </ReactQueryConfigProvider>
  );
};

function App({
  history = createBrowserHistory(),
  queryConfig = { queries: { refetchOnWindowFocus: false } },
  hideDevtools = false,
}: {
  history?: History<unknown>;
  queryConfig?: ReactQueryConfig;
  hideDevtools?: boolean;
}): JSX.Element {
  return (
    <AppProviders history={history} queryConfig={queryConfig}>
      <Routes />
      {hideDevtools && <ReactQueryDevtools position="bottom-right" />}
    </AppProviders>
  );
}

export default App;
