import { Alert } from '@ver-uds/uswds-react';
import React from 'react';

export const ConfirmationRequired = ({ email }: { email?: string }): JSX.Element => {
  return (
    <section>
      <h1>Confirmation Required</h1>
      <div className="padding-bottom-4 width-tablet-lg">
        <p>
          Within a few minutes you should receive an email
          {email ? (
            <>
              {' '}
              at <b>{email}</b>
            </>
          ) : null}{' '}
          to confirm your account and create your password. Please check email inboxes as well as spam or junk mail
          folders for emails sent from no-reply@uscis.dhs.gov, as sometimes the emails are mistakenly marked as spam. If
          the confirmation email is not received within 24 hours, email{' '}
          <a
            href="mailto:E-Verify@uscis.dhs.gov"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Send an email to E-Verify@uscis.dhs.gov"
          >
            E-Verify@uscis.dhs.gov
          </a>{' '}
          for assistance.
        </p>
        <Alert status="info">
          <Alert.Text>
            If you do not receive the confirmation email, please do not create another account. If you attempt to create
            another account, your enrollment may be delayed.
          </Alert.Text>
        </Alert>
      </div>
    </section>
  );
};

export default ConfirmationRequired;
