/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Application, UserStatus } from '@vis-auth/vis-user-client';
import { useHistory, useLocation } from 'react-router-dom';
import { legacyIdpApi } from '../../userService';
import mapErrors from '../../map-errors';
import OneTimeLoginForm from '../../components/OneTimeLoginForm/OneTimeLoginForm';
import AppShell from '../../components/Layouts/AppShell';

const oneTimeLogin = async (logonId: string, client: string) => {
  try {
    const result = await legacyIdpApi.sendOTC({
      sendOneTimeCodeRequest: {
        logonId,
        app: client as Application,
      },
    });

    return result;
  } catch (err) {
    throw new Error(await mapErrors(err as Response));
  }
};

const SendOtc = (): JSX.Element => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  const searchParams = new URLSearchParams(useLocation().search);
  const error = searchParams.get('error') === 'true';
  const appParam = searchParams.get('app') ?? '';

  let app = '';
  let appName = 'app';
  if (appParam.toLocaleLowerCase() === Application.SAVE.toLocaleLowerCase()) {
    app = Application.SAVE;
    appName = 'SAVE';
  } else if (appParam.toLocaleLowerCase() === Application.EVERIFY.toLocaleLowerCase()) {
    app = Application.EVERIFY;
    appName = 'E-Verify';
  }

  if (!app || app === '') {
    throw new Error('Application context missing.');
  }

  const onCancel = (): void => {
    history.push('/');
  };

  if (error && !errorMessage) {
    setErrorMessage('Your password reset link is invalid. Enter your User ID to send a new password reset message.');
  }

  return (
    <AppShell app={app}>
      <OneTimeLoginForm
        forgotUserIdUrl="/forgot-user-id"
        userStatus={UserStatus.UNKNOWN}
        onSubmit={async (u): Promise<void> => {
          return oneTimeLogin(u, app)
            .then(() => {
              history.push(`/change-pw/otc-message-sent?app=${app}&userStatus=${UserStatus.UNKNOWN}`);
            })
            .catch((apiError: Error) => {
              setErrorMessage(apiError.message.replaceAll('{{app}}', appName || 'app'));
            });
        }}
        onCancel={onCancel}
        errorMessage={errorMessage}
      />
    </AppShell>
  );
};

export default SendOtc;
