import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Application, legacyIdpApi } from '../../userService';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import mapErrors from '../../map-errors';
import AlertModal from '../../components/Modals/AlertModal';
import { useEnvironment } from '../../components/Environment/EnvironmentContext';
import AppShell from '../../components/Layouts/AppShell';

async function changePasswordPut({ userId, newPassword }: { userId: string; newPassword: string }): Promise<void> {
  const passwordChangeBody = { newPassword };

  try {
    return await legacyIdpApi.updatePassword({
      id: userId,
      passwordChangeBody,
    });
  } catch (err) {
    // The error caught is a Response type object, not an Error. Re-throw a true Error.
    throw new Error(await mapErrors(err as Response));
  }
}

const OtcChangePassword = (): JSX.Element => {
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);

  let errorMessage = '';
  const title = 'Change Password';
  const instructions = '';
  const submitButtonText = 'Save Password';
  const currentPasswordRequired = false;

  const env = useEnvironment();
  const searchParams = new URLSearchParams(useLocation().search);
  const userId = searchParams.get('userId');
  const appParam = searchParams.get('app') ?? '';

  let app;
  let redirectUri = '/error';
  if (appParam.toLocaleLowerCase() === Application.SAVE.toLocaleLowerCase()) {
    app = Application.SAVE;
    redirectUri = env.SAVE_ENROLLMENT_REDIRECT;
  } else if (appParam.toLocaleLowerCase() === Application.EVERIFY.toLocaleLowerCase()) {
    app = Application.EVERIFY;
    redirectUri = env.EVE_ENROLLMENT_REDIRECT;
  }

  if (!userId) {
    throw new Error('User ID missing.');
  }

  if (!app) {
    throw new Error('Application context missing.');
  }

  const closePasswordChangeModal = () => {
    setPasswordChangeModalOpen(false);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    window.location.replace(redirectUri);
  };

  const [mutate, { status, error }] = useMutation(changePasswordPut, {
    onSuccess: () => {
      setPasswordChangeModalOpen(true);
    },
  });

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  const onSubmit = async ({ newPassword }: { newPassword: string }): Promise<void> => {
    await mutate({
      userId,
      newPassword,
    });
  };

  let appName = '';
  if (app === Application.EVERIFY) {
    appName = 'E-Verify ';
  } else if (app === Application.SAVE) {
    appName = 'SAVE ';
  }
  const passwordChangeModalHeaderText = 'Password Change Successful';
  const passwordChangeModalContentHeading = 'Your password has been successfully changed.';
  const passwordChangeModalContentText = 'Please sign back in with your new password to continue.'; // 'For your security, all current sessions have been signed out. Please sign back in with your new password to continue.';
  const passwordChangeModalButtonText = `Continue to ${appName}Sign In Page`;

  return (
    <AppShell app={app}>
      <ChangePasswordForm
        onSubmit={onSubmit}
        submitBtnText={submitButtonText}
        status={status}
        helmet={title}
        title={title}
        instructions={instructions}
        currentPassRequired={currentPasswordRequired}
        currentPassLabelHint="(required)"
        newPassLabelHint="(required)"
        confirmPassLabelHint="(required)"
        errorMessage={errorMessage}
        logonId={userId}
      />
      <AlertModal
        alertStatus="success"
        contentHeading={passwordChangeModalContentHeading}
        contentText={passwordChangeModalContentText}
        headerText={passwordChangeModalHeaderText}
        buttonText={passwordChangeModalButtonText}
        isOpen={passwordChangeModalOpen}
        onClose={closePasswordChangeModal}
      />
    </AppShell>
  );
};

export default OtcChangePassword;
