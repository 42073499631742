import { Configuration, OIDCApi, LegacyIDPApi, UserApi } from '@vis-auth/vis-user-client';

export * from '@vis-auth/vis-user-client';

export const userServiceBasePath = '/api/users';

export const config = new Configuration({
  basePath: `${window.location.origin}${userServiceBasePath}`,
});

export const oidcApi = new OIDCApi(config);

export const legacyIdpApi = new LegacyIDPApi(config);

export const userApi = new UserApi(config);
