import React, { ReactNode } from 'react';
import { Banner, BannerExtension, SkipNav } from '@ver-uds/uswds-react';
import styled from 'styled-components';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Client } from '../Providers/OAuthParamsParser';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled(SkipNav.MainContent)`
  flex: 1 0 auto;
`;

const AppShell = ({ app, children }: { app: string; children?: ReactNode }): JSX.Element => (
  <FlexContainer>
    <SkipNav.Anchor />
    <Banner extensionType={BannerExtension.GOV} />
    <Header appName={app as Client} />
    <MainContent className="grid-container width-full">
      <div className="grid-row grid-gap-3 margin-y-8">{children}</div>
    </MainContent>
    {/* Wrapper div because <Footer /> has style issues on IE 11 in a flex container */}
    <div>
      <Footer appName={app as Client} />
    </div>
  </FlexContainer>
);

export default AppShell;
