import React, { useContext, useEffect, useState, FunctionComponent } from 'react';

const FEATURE_FLAGS_FILE_PATH = `${process.env.PUBLIC_URL}/featureFlags.json`;

export type Feature =
  | 'testFeatureFlagOn'
  | 'testFeatureFlagOff'
  | 'testFeatureFlagToggle'
  | 'showFeatureFlagsStatus'
  | 'showEveOutageBanner'
  | 'isLoginDotGovEnabledSAVE'
  | 'isLoginDotGovEnabledEVerify';

export type FeatureFlags = Record<Feature, boolean>;

const FeatureFlagsContext = React.createContext<FeatureFlags | null>(null);

export const useFeatureFlags = (): FeatureFlags => {
  const featureFlags = useContext(FeatureFlagsContext);
  if (!featureFlags) {
    throw new Error('useFeatureFlags must be used inside FeatureFlagsProvider');
  }
  return featureFlags;
};

export const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;

async function fetchFeatureFlags(): Promise<FeatureFlags> {
  const response = await fetch(FEATURE_FLAGS_FILE_PATH);
  const responseJson = (await response.json()) as FeatureFlags;
  return responseJson;
}

export const FeatureFlagsProvider: FunctionComponent = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);

  useEffect(() => {
    let isMounted = true;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchFeatureFlags().then((responseJson: FeatureFlags) => {
      if (isMounted) {
        setFeatureFlags(responseJson);
      }
    });

    // clean up useEffect to avoid running after a component is unmounted
    // https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
    return (): void => {
      isMounted = false;
    };
  }, []);

  if (featureFlags) {
    return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
  }

  return <> Loading Feature Flags... </>;
};
