import { Button } from '@ver-uds/react';
import { Application } from '@vis-auth/vis-user-client';
import React from 'react';
import PaperworkReductionActModal from '../Modals/PaperworkReductionActModal';
import PrivacyActModal from '../Modals/PrivacyActModal';

export const TermsOfUse = ({ app, setTermsAccepted }: { app: Application; setTermsAccepted: () => void }) => {
  return (
    <section className="padding-bottom-2 width-full">
      <h1>Use Agreement</h1>
      <p className="width-tablet-lg">
        You are about to access a Department of Homeland Security computer system. This computer system and data therein
        are property of the U.S. Government and provided for official U.S. Government information and use. There is no
        expectation of privacy when you use this computer system. The use of a password or any other security measure
        does not establish an expectation of privacy. By using this system, you consent to the terms set forth in this
        notice. You may not process classified national security information on this computer system. Access to this
        system is restricted to authorized users only. Unauthorized access, use, or modification of this system or of
        data contained herein, or in transit to/from this system, may constitute a violation of section 1030 of title 18
        of the U.S. Code and other criminal laws. Anyone who accesses a Federal computer system without authorization or
        exceeds access authority, or obtains, alters, damages, destroys, or discloses information, or prevents
        authorized use of information on the computer system, may be subject to penalties, fines, or imprisonment. This
        computer system and any related equipment is subject to monitoring for administrative oversight, law
        enforcement, criminal investigative purposes, inquiries into alleged wrongdoing or misuse, and to ensure proper
        performance of applicable security features and procedures. DHS may conduct monitoring activities without
        further notice.
      </p>
      <p>
        By clicking &ldquo;I agree&rdquo; below or by using this system, you consent to the terms set forth in this
        notice.
      </p>
      <div className="padding-y-2 display-flex flex-row flex-align-center">
        <hr className="width-full" />
      </div>
      <div className="display-flex flex-row flex-justify padding-y-2">
        <Button onClick={() => setTermsAccepted()}>I Agree</Button>
        <div className="display-flex flex-row flex-align-center flex-justify">
          {app === Application.SAVE ? <PrivacyActModal isStyled /> : null}
          <PaperworkReductionActModal app={app} isStyled className="margin-left-2" />
        </div>
      </div>
      {app === Application.EVERIFY ? (
        <div className="display-flex flex-row flex-justify-end">
          <p>OMB Control No. 1615-0092 • Expiration Date 12/31/2024</p>
        </div>
      ) : null}
    </section>
  );
};

export default TermsOfUse;
