import { Button } from '@ver-uds/react';
import { ButtonProps } from '@ver-uds/react/dist/Button/Button';
import { ForwardRefExoticComponent } from 'react';
import styled from 'styled-components';

const UnstyledButton = styled(Button)`
  && {
    justify-content: normal;

    font-weight: normal;
    text-decoration: underline;
  }
` as ForwardRefExoticComponent<HTMLButtonElement | ButtonProps>;

export default UnstyledButton;
