import React, { useState } from 'react';
import { Modal, Button } from '@ver-uds/react';
import UnstyledButton from '../UnstyledButton/UnstyledButton';

export interface PrivacyActModalProps {
  isStyled?: boolean;
}

const PrivacyActModal = ({ isStyled = false }: PrivacyActModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isStyled ? (
        <Button variant="tertiary" onClick={() => setIsOpen(true)}>
          Privacy Act
        </Button>
      ) : (
        <UnstyledButton type="button" variant="tertiary" onClick={(): void => setIsOpen(true)}>
          Privacy Act
        </UnstyledButton>
      )}
      <Modal id="privacy-act-modal" header="Privacy Act" open={isOpen} close={(): void => setIsOpen(false)}>
        <Modal.Content>
          <p>
            The information provided by this system is subject to the Privacy Act of 1974 (5 U.S.C. &sect; 552a) and
            must be protected from unauthorized disclosure or secondary uses. In accordance with the Privacy Act, the
            information collected and provided to authorized agencies pursuant to this system is covered by the System
            of Records Notice &quot;DHS/USCIS - Systematic Alien Verification for Entitlements Program System of
            Records&quot; September 21, 2011, 76 FR 58525.
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button type="button" variant="primary" onClick={(): void => setIsOpen(false)}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrivacyActModal;
