import { Alert } from '@ver-uds/uswds-react';
import React from 'react';

const EveOutageWarning = (): JSX.Element => {
  return (
    <Alert
      className="margin-x-auto padding-x-105 grid-col tablet:grid-col-7 desktop:grid-col-6 margin-bottom-7"
      status="warning"
    >
      <Alert.Heading>System Outage</Alert.Heading>
      <Alert.Text>
        E-Verify is currently experiencing technical difficulties. As a result, employers may not be able to fully
        access their E-Verify accounts and process cases. E-Verify is working to resolve this issue as soon as possible.
      </Alert.Text>
    </Alert>
  );
};

export default EveOutageWarning;
