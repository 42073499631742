import { PageLoader } from '@ver-uds/react';
import { Application, User, ValidationErrorCode, VerifyOtcRequest } from '@vis-auth/vis-user-client';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import { QueryConfig, useQuery } from 'react-query';
import { legacyIdpApi } from '../../userService';
import mapErrors from '../../map-errors';

const verify = async (requestParameters: VerifyOtcRequest): Promise<User> => {
  try {
    const result = await legacyIdpApi.verifyOtc(requestParameters);
    return result;
  } catch (err) {
    throw new Error(await mapErrors(err as Response));
  }
};

const usePwdVerifyOtc = (requestParameters: VerifyOtcRequest, options?: QueryConfig<User, ValidationErrorCode>) => {
  return useQuery<User, ValidationErrorCode>('verify', () => verify(requestParameters), options);
};

const OtcVerify = (): JSX.Element => {
  const history = useHistory();
  const searchParams = new URLSearchParams(useLocation().search);
  const logonId = searchParams.get('logonid') ?? '';
  const code = searchParams.get('code') ?? '';
  const appParam = searchParams.get('app') ?? '';

  let app = '';
  if (appParam.toLocaleLowerCase() === Application.SAVE.toLocaleLowerCase()) {
    app = Application.SAVE;
  } else if (appParam.toLocaleLowerCase() === Application.EVERIFY.toLocaleLowerCase()) {
    app = Application.EVERIFY;
  }

  usePwdVerifyOtc({ verifyOTCRequest: { OTC: code, app, logonId } } as VerifyOtcRequest, {
    retry: false,
    onSuccess: (result: User) => {
      history.push(`/change-pw/change-password?userId=${result.id}&app=${app}`);
    },
    onError: () => {
      history.push(`/change-pw/send-otc?app=${app}&error=true`);
    },
  });

  return (
    <>
      <Helmet>
        <title>Verifying One Time Code</title>
      </Helmet>
      <PageLoader label="Verifying One Time Code" />
    </>
  );
};

export default OtcVerify;
